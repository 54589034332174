import { useState, useRef, useEffect, Suspense, useCallback, useMemo } from "react"
import * as THREE from 'three'

import { Canvas, useFrame } from "@react-three/fiber"
import GradientBg from "../utils/gradientbg.jsx"
import { AccumulativeShadows, Center, PerspectiveCamera, Environment, OrbitControls, Resize, RandomizedLight, useGLTF, Decal, RenderTexture, OrthographicCamera, Text } from "@react-three/drei"
import { FlakesTexture } from 'three-stdlib'
import PostProcessing from "../utils/postprocessing.jsx"
import FixedControls from "../utils/fixedcontrols.tsx"


export default function Demo5() {

    const [text, setText] = useState(getCurrentDate());
    const [inputValue, setInputValue] = useState(getCurrentDate());
    const debounceTimeoutRef = useRef();

    const handleTextChange = useCallback((e) => {
        const value = e.target.value;
        setInputValue(value);

        if (debounceTimeoutRef.current) {
            clearTimeout(debounceTimeoutRef.current);
        }

        debounceTimeoutRef.current = setTimeout(() => {
            setText(value);
        }, 500);
    }, []);

    useEffect(() => {
        return () => {
            if (debounceTimeoutRef.current) {
                clearTimeout(debounceTimeoutRef.current);
            }
        }
    }, []);

    const memoScene = useMemo(() => <>
        <PostProcessing focusPlane={0.0005} bloomIntensity={1} target={[-0.3, -0.3, 0.15]} />
        <Environment files="./hdri/custom9.hdr" />
        <GradientBg contrast={0.7} color={"#C88C5D"} />

    </>, []);
    const memoModel = useMemo(() => <>

        <Ring text={text} />
    </>, [text]);

    return <>
        <Canvas shadows camera={{ fov: 20, near: 0.1, far: 100 }} frameloop="demand" className="demo-canvas" >

            <FixedControls cursor zoomInit={5} rotationInit={[0.7, 0]} zoom={[1.5, 6]} speed={[2.5, 2.5]}>
                <Suspense>{memoModel}</Suspense>
            </FixedControls>

            <Suspense>{memoScene}</Suspense>

        </Canvas>
        <div className="demo-controls">
            <div className="demo-controls-item">
                Text
                <input className="text-input" type="text" value={inputValue} maxLength={25} onChange={handleTextChange} />
            </div>
        </div>
    </>
}

function Ring(props) {
    const { nodes, materials } = useGLTF("./models/bulgari.glb")

    const memoShadow = useMemo(() => <Shadow />, []);


    const fontSize = 4 - Math.max(props.text.length - 4, 0) / 9.5
    return <>
        <Center>
            <Resize height>
                <group rotation-z={Math.PI} rotation-y={2.7} dispose={null}>
                    <group >
                        <mesh castShadow receiveShadow geometry={nodes.GRAVURE_Mesh5.geometry} material={materials["lambert4.001"]} material-roughness={0} />
                        <mesh castShadow receiveShadow geometry={nodes.Mesh.geometry} material={materials["lambert4.001"]} material-roughness={0} />
                        <mesh castShadow receiveShadow geometry={nodes.Mesh1.geometry} material={materials["lambert4.001"]} material-roughness={0} />
                    </group>
                    <mesh castShadow receiveShadow geometry={nodes.BVLGARI_C_center_geo.geometry} material={materials["blinn3.001"]} material-roughness={0}>
                        <Decal position={[2.5, 2.6, -3.02]} rotation={[-1.66, 0.8, 0]} scale={[6, 1, 1]} >
                            <meshStandardMaterial roughness={0.2} metalness={0.8} transparent polygonOffset polygonOffsetFactor={-10} normalMap={new THREE.CanvasTexture(new FlakesTexture(), THREE.UVMapping, THREE.RepeatWrapping, THREE.RepeatWrapping)} normalMap-repeat={[20, 5]}>
                                <RenderTexture attach={"map"}>
                                    <PerspectiveCamera makeDefault manual aspect={6} position={[0, 0, 5]} />
                                    <Text rotation={[0, Math.PI, 0]} fontSize={fontSize} color="#4E3F2B" font="./fonts/LibreBaskerville-Italic.ttf">
                                        {props.text}
                                    </Text>

                                </RenderTexture>

                            </meshStandardMaterial>
                        </Decal>
                    </mesh>
                </group>
            </Resize>
        </Center>
        <Suspense>{memoShadow}</Suspense>

    </>

}

function Shadow() {
    return <>
        <AccumulativeShadows frames={150} toneMapped alphaTest={1} opacity={0.8} scale={3} color="#FFAA2F" colorBlend={1} position-y={-0.7} >
            <RandomizedLight amount={10} radius={6} intensity={1} ambient={0.6} position={[0, 4, 0]} bias={0.001} />
        </AccumulativeShadows>
    </>

}


function getCurrentDate() {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so we need to add 1.
    const year = today.getFullYear();

    return `${day}.${month}.${year}`;
}