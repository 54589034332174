import { useState, useRef, Suspense, useCallback, useMemo, useEffect } from "react"
import { Canvas, extend } from "@react-three/fiber"
import GradientBg from "../utils/gradientbg.jsx"
import { AccumulativeShadows, Center, Environment, OrbitControls, OrthographicCamera, Resize, RandomizedLight, useGLTF, useTexture, ContactShadows, MeshReflectorMaterial, RenderTexture, PerspectiveCamera, Text, shaderMaterial } from "@react-three/drei"
import { RoundedBox } from "../utils/roundedbox.tsx"
import * as THREE from 'three'
import FixedControls from "../utils/fixedcontrols.tsx"


export default function Demo4() {
    const [height, setHeight] = useState(0.5)
    const [width, setWidth] = useState(0.2)
    const [roundness, setRoundness] = useState(0)
    const [bevel, setBevel] = useState(0)
    const [orientation, setOrientation] = useState(0)
    const [color, setColor] = useState("#001A47")

    const handleHeightChange = useCallback((e) => { setHeight(parseFloat(e.target.value)); }, []);
    const handleWidthChange = useCallback((e) => { setWidth(parseFloat(e.target.value)); }, []);
    const handleRoundnessChange = useCallback((e) => { setRoundness(parseFloat(e.target.value)); }, []);
    const handleBevelChange = useCallback((e) => { setBevel(parseFloat(e.target.value)); }, []);
    const handleOrientationChange = useCallback((e) => { setOrientation(parseFloat(e.target.value)); }, []);
    const handleColorChange = useCallback((e) => { setColor(e.target.value) }, []);


    const memoScene = useMemo(() => <Scene />, []);
    const memoModel = useMemo(() => <Model />, []);

    return <>
        <Canvas shadows camera={{ fov: 15, near: 0.1, far: 100 }} frameloop="demand" className="demo-canvas" >
            <Suspense>{memoScene}</Suspense>
            <group position-y={0.2}>
                <FixedControls cursor zoomInit={7.5} rotationInit={[0.15, 0]} zoom={[3, 10]} polar={[-0.3, Math.PI / 2]} >
                    <Suspense>{memoModel}</Suspense>
                </FixedControls>

                <Suspense>
                    <Stand height={height} width={width} roundness={roundness} bevel={bevel} color={color} orientation={orientation} />
                </Suspense>

            </group>
        </Canvas>

        <div className="demo-controls">
            <div className="demo-controls-item">
                Height <input type="range" className="slider-input" min={0} max={1} step={0.01} value={height} onChange={handleHeightChange} />
            </div>
            <div className="demo-controls-item">
                Width <input type="range" className="slider-input" min={0} max={1} step={0.01} value={width} onChange={handleWidthChange} />
            </div>
            <div className="demo-controls-item">
                Roundness <input type="range" className="slider-input" min={0} max={1} step={0.01} value={roundness} onChange={handleRoundnessChange} />
            </div>
            <div className="demo-controls-item">
                Bevel <input type="range" className="slider-input" min={0} max={1} step={0.01} value={bevel} onChange={handleBevelChange} />
            </div>
            <div className="demo-controls-item">
                Orientation <input type="range" className="slider-input" min={-90} max={90} step={1} value={orientation} onChange={handleOrientationChange} />
            </div>
            <div className="demo-controls-item">
                Color <input className="color-input" type="color" value={color} onChange={handleColorChange} />
            </div>
        </div>
    </>
}

function Scene() {
    return <>
        <GradientBg contrast={0.25} color={"#E1E1E1"} />
        <Environment files="./hdri/custom9.hdr" resolution={512}>
            <mesh position={[-0.2, 2, 5]} scale={[1.4, 4, 1]} rotation-x={-0.8}>
                <boxGeometry />
                <meshBasicMaterial color={'black'} />
            </mesh>
            <mesh position={[2, 1, -2]} scale-y={4}>
                <boxGeometry />
                <meshStandardMaterial emissive={"#FFF"} emissiveIntensity={5} />
            </mesh>
        </Environment>
    </>
}

function Model(props) {
    const { scene } = useGLTF("./models/chloe.glb")
    scene.traverse((obj) => (obj.isMesh && (obj.castShadow = true)));
    return <>
        <Center>
            <Resize height>
                <group rotation-z={-0.15}>
                    <primitive object={scene} />
                </group>
            </Resize>
        </Center>

        <AccumulativeShadows frames={150} toneMapped alphaTest={1.1} opacity={0.9} scale={3} position-y={-0.498} >
            <RandomizedLight amount={10} radius={6} intensity={1} ambient={0.6} position={[0, 10, 0]} bias={0.001} />
        </AccumulativeShadows>
    </>
}

function Stand(props) {
    const bevel = props.bevel * props.bevel / 10 + 0.005
    const width = props.width + 1.05 - bevel * 2
    const roundness = Math.max(props.roundness, bevel) * width * 0.48
    const height = Math.max(props.height * props.height * 1.5 + 0.025, bevel * 2 + 0.03)
    var segments = 3
    if (props.bevel < 0.62) {
        segments = 2
    }
    if (props.bevel < 0.56) {
        segments = 1
    }
    const texture = useRef()

    const updateUV = () => {
        if (texture.current) {
            texture.current.offset.x = (roundness + bevel) * 0.71
            texture.current.offset.y = 0
            texture.current.center = new THREE.Vector2((roundness + bevel) * -0.71, 0)
            texture.current.repeat.x = 1 / (width - 0.58 * roundness + bevel * 1.4)
            texture.current.repeat.y = 1 / width
            texture.current.wrapS = THREE.RepeatWrapping
            texture.current.wrapT = THREE.RepeatWrapping
        }
    }
    useEffect(() => {
        updateUV()
    }, [])
    updateUV()

    return <>
        <RoundedBox rotation-z={(-90 - props.orientation) / 360 * Math.PI} args={[width, width, height]} radius={bevel} roundness={roundness} position-y={-height / 2 - 0.5} rotation-x={Math.PI / 2} smoothness={parseInt(2 + (1.5 * props.bevel))}>
            <meshStandardMaterial roughness={0}>
                <RenderTexture attach="map" ref={texture} >
                    <MarbleScene color={props.color} />
                </RenderTexture>
            </meshStandardMaterial>
        </RoundedBox>
        <ContactShadows frames={1} position-y={-height - 0.5} blur={0.5 + 1 * props.bevel} scale={width + 1} resolution={1024} opacity={0.9} color="#FF0000" />
    </>

}


const MarbleMaterial = shaderMaterial(
    // Uniforms
    {
        uTexture: { value: null },
        uColor: { value: null },
    },
    // Vertex shader
    `
    varying vec2 vUv;
  
    void main() {
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
    `,
    // Fragment shader
    `
    uniform sampler2D uTexture;
    uniform vec3 uColor;

    varying vec2 vUv;

    vec3 rgb2hsv(vec3 c) {       
    vec4 K = vec4(0.0, -1.0 / 3.0, 2.0 / 3.0, -1.0);
    vec4 p = mix(vec4(c.bg, K.wz), vec4(c.gb, K.xy), step(c.b, c.g));
    vec4 q = mix(vec4(p.xyw, c.r), vec4(c.r, p.yzx), step(p.x, c.r));
    float d = q.x - min(q.w, q.y);
    float e = 1.0e-10;
    return vec3(abs(q.z + (q.w - q.y) / (6.0 * d + e)), d / (q.x + e), q.x);
    }

    vec3 hsv2rgb(vec3 c) {
    vec4 K = vec4(1.0, 2.0 / 3.0, 1.0 / 3.0, 3.0);
    vec3 p = abs(fract(c.xxx + K.xyz) * 6.0 - K.www);
    return c.z * mix(K.xxx, clamp(p - K.xxx, 0.0, 1.0), c.y);
    }
  
    void main() {
        vec4 texture = texture2D(uTexture, vUv);
        vec3 hsvTexture = rgb2hsv(texture.xyz);
        vec3 hsvColor = rgb2hsv(uColor);

        float hue = mod(hsvTexture.x + hsvColor.x, 1.0);
        float sat = clamp(hsvTexture.y * hsvColor.y, 0.0, 1.0);
        float val = pow(hsvTexture.z, 3.0 * pow(0.0625, hsvColor.z));
        vec3 final = hsv2rgb(vec3(hue, sat, val));


        gl_FragColor = vec4(final, 1.0);
    }
    `
);

extend({ MarbleMaterial })

function MarbleScene(props) {
    const texture = useTexture('./maps/marble.jpg')
    return <>
        <OrthographicCamera makeDefault position={[0, 0, 1]} top={0.5} bottom={-0.5} left={-0.5} right={0.5} />
        <color attach="background" args={['orange']} />
        <mesh>
            <planeGeometry />
            <marbleMaterial uniforms-uTexture-value={texture} uniforms-uColor-value={new THREE.Color(props.color)} />
        </mesh>
    </>
}






