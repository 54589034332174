import { useState, useEffect, useRef } from "react";

export default function Fade({ children, delay = 0, scroll = true }) {
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef(null);

    useEffect(() => {

        const container = ref.current;
        const childElements = container.children;

        let top = -Infinity;
        for (let i = 0; i < childElements.length; i++) {
            const childRect = childElements[i].getBoundingClientRect();
            top = Math.max(top, childRect.top);
        }


        const onLoad = () => {
            const isVisible = top <= window.scrollY + window.innerHeight
            if (isVisible) {
                setTimeout(() => {
                    setIsVisible(true)
                }, 150)
            }
        };

        setTimeout(() => {
            onLoad()
            window.addEventListener("scroll", onLoad)

        }, delay * 1000);

    });

    return (
        <div ref={ref} className={`fade-in ${isVisible ? "is-visible" : ""} ${scroll ? "scroll" : ""}`}>
            {children}
        </div>
    );
}
