import { Link, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { Perf } from "r3f-perf";


import Demo1 from "./demos/demo1.jsx";
import Demo2 from "./demos/demo2.jsx";
import Demo3 from "./demos/demo3.jsx";
import Demo4 from "./demos/demo4.jsx";
import Demo5 from "./demos/demo5.jsx";
import Demo6 from "./demos/demo6.jsx";
import Fade from "./utils/fade.jsx";



export default function Demos() {
    const location = useLocation()

    var demoNumber = parseInt(location.pathname.match(/\/demos-(\d+)/)[1]);
    if (isNaN(demoNumber) || demoNumber < 0 || demoNumber > 6) {
        demoNumber = 1
    }
    const previous = `/demos-${Math.max(demoNumber - 1, 1)}`
    const next = `/demos-${Math.min(demoNumber + 1, 6)}`

    const titles = {
        1: "1 — Backdrop emulation",
        2: "2 — Post-processing",
        3: "3 — Lighting studio",
        4: "4 — Display stand",
        5: "5 — Custom writing",
        6: "6 — Natural controls"
    }
    const descriptions = {
        1: "This replicates the look of a cyclorama photoshoot.",
        2: 'Applying post-processing effects, proper color management and a color grading pass can help remove the infamous "3D look".\nAlso notice how the depth of field really conveys the true size of the object. Everything gets blurry very quickly, like when shooting a miniature set.',
        3: "This tool lets you easily put together a beautiful lighting setup regardless of your 3D skills.",
        6: "Sometimes you'll want to zoom in on a specific area on a 3D object. However, supporting this behavior presents challenges with 3D camera positioning that can break the experience if unaddressed.",

    }


    return <>
        <Fade scroll={false}>
            <div className='section demo'>
                <div className="demo-text" >
                    <div className="demo-title">{titles[demoNumber]}</div>
                    {descriptions[demoNumber] ? <>{descriptions[demoNumber].split('\n').map((item, key) => {
                        return <p key={key}>{item}<br /></p>
                    })}</> : null}
                </div>
                {demoNumber === 1 ? <Demo1 /> : null}
                {demoNumber === 2 ? <Demo2 /> : null}
                {demoNumber === 3 ? <Demo3 /> : null}
                {demoNumber === 4 ? <Demo4 /> : null}
                {demoNumber === 5 ? <Demo5 /> : null}
                {demoNumber === 6 ? <Demo6 /> : null}
                <div className="demo-footer">
                    <div className="demo-nav">
                        <Link className={`${demoNumber === 1 ? 'arrow-disabled' : ''} arrow arrow-left`} to={previous} />
                        <Link className={`${demoNumber === 6 ? 'arrow-disabled' : ''} arrow arrow-right`} to={next} />
                    </div>
                    {/* <div className="counter">
                    {demoNumber}/6
                </div> */}

                </div>
            </div>
        </Fade>

    </>
}