import { useRef, useContext, useEffect } from "react"
import { useFrame, useThree } from "@react-three/fiber"
import { Bloom, DepthOfField, EffectComposer, EffectComposerContext } from "@react-three/postprocessing"
import * as THREE from "three"
import { clamp } from "three/src/math/MathUtils"


export default function PostProcessing({ focusPlane = 0.0006, offset = 0.008, isBloom = true, bloomIntensity = 0.1, minDist = 0, target = [0, 0, 0] }) {
    let camCopy = new THREE.Vector3();
    let offset3 = new THREE.Vector3(offset, offset, offset)
    let finalTarget
    let distance
    let bokeh
    let initialTarget = new THREE.Vector3(target[0], target[1], target[2])



    const debugRef = useRef()

    const ref = useDepthOfFieldEffect();
    const { camera } = useThree()

    useFrame(() => {
        camCopy.copy(camera.position)
        camCopy.normalize()
        finalTarget = camCopy.multiply(offset3).add(initialTarget)
        ref.current.target = finalTarget
        distance = camera.position.distanceTo(finalTarget)
        ref.current.cocMaterial.uniforms.focalLength.value = distance * focusPlane
        bokeh = clamp(-10 * (distance - minDist) + 12, 5, 20)
        ref.current.bokehScale = bokeh
    })
    return <>
        <EffectComposer >
            <DepthOfField focalLength={0.0001} bokehScale={7} ref={ref} />
            {isBloom && <Bloom luminanceThreshold={1.1} luminanceSmoothing={1} intensity={bloomIntensity} blendFunction={0} />}
        </EffectComposer>
    </>
}


function useDepthOfFieldEffect() {
    const composer = useContext(EffectComposerContext);
    const depthOfFieldEffectRef = useRef();

    useEffect(() => {
        if (composer && depthOfFieldEffectRef.current) {
            composer.addPass(depthOfFieldEffectRef.current);
        }
    }, [composer]);

    return depthOfFieldEffectRef;
}