import * as React from 'react'
import * as THREE from 'three'
import { MathUtils } from 'three'
import { useFrame, useThree } from '@react-three/fiber'
import { a, useSpring, to } from '@react-spring/three'
import { useGesture } from '@use-gesture/react'
import { SpringConfig } from '@react-spring/core'

export type FixedControlProps = {
    snap?: Boolean | SpringConfig
    global?: boolean
    cursor?: boolean
    speed?: [number, number]
    rotationInit?: [number, number]
    polar?: [number, number]
    // Not supported with inertia
    azimuth?: [number, number]
    zoom?: [number, number]
    zoomInit?: number
    config?: any
    enabled?: boolean
    children?: React.ReactNode
    domElement?: HTMLElement
}

export default function FixedControls({
    enabled = true,
    snap,
    global = true,
    domElement,
    cursor = true,
    children,
    speed = [2.5, 1],
    rotationInit = [0, 0],
    polar = [-Math.PI / 2, Math.PI / 2],
    azimuth = [-Infinity, Infinity],
    zoom = [0, Infinity],
    zoomInit = 5,
    config = { mass: 0, tension: 1, friction: 1 },
}: FixedControlProps) {
    const events = useThree((state) => state.events)
    const gl = useThree((state) => state.gl)
    const explDomElement = domElement || events.connected || gl.domElement
    const { size, camera, invalidate } = useThree()

    const zInitial = React.useMemo(
        () => MathUtils.clamp(zoomInit, ...zoom),
        [...zoom]
    )

    const rInitial = React.useMemo(() => [MathUtils.clamp(rotationInit[0], ...polar), MathUtils.clamp(rotationInit[1], ...azimuth), 0],
        [polar[0], polar[1], azimuth[0], azimuth[1]]
    )

    React.useEffect(() => void api.start({ rotation: rInitial, config }), [rInitial])
    React.useEffect(() => {

        if (global && cursor && enabled) {
            explDomElement.style.cursor = 'grab'

            gl.domElement.style.cursor = ''
        }

        return () => {
            explDomElement.style.cursor = 'default'
            gl.domElement.style.cursor = 'default'
        }
    }, [global, cursor, explDomElement, enabled])


    const [spring, api] = useSpring(() => ({
        rotation: rInitial,
        config, onChange: (e) => {
            invalidate()
            setRotationState(e.value.rotation)
        }
    }))


    const [rotationState, setRotationState] = React.useState(rInitial)
    const [zoomState, setZoomState] = React.useState(zInitial)


    const [rotationStateDamped, setRotationStateDamped] = React.useState(rInitial)
    const [zoomStateDamped, setZoomStateDamped] = React.useState(zInitial)


    const [pinching, setPinching] = React.useState(false);



    const bind = useGesture(
        {
            onHover: ({ last }) => {
                if (cursor && !global && enabled) explDomElement.style.cursor = last ? 'auto' : 'grab'
            },

            onDrag: ({
                down,
                delta: [x, y],
                velocity,
                direction,
                memo: [oldY, oldX] = rotationState || rInitial,
                cancel,
            }) => {
                if (!enabled) return [y, x]

                if (pinching) return cancel()
                if (cursor) explDomElement.style.cursor = down ? 'grabbing' : 'grab'
                x = MathUtils.clamp(oldX + (x / size.width) * Math.PI * speed[0], ...azimuth)
                y = MathUtils.clamp(oldY + (y / size.height) * Math.PI * speed[1], ...polar)
                const sConfig = snap && !down && typeof snap !== 'boolean' ? snap : config
                const azimuthVelocity = direction[0] * velocity[0] * speed[0] / 1000 * 5
                api.start({
                    rotation: snap && !down ? rInitial : [y, x, 0],
                    config: (n) => {
                        if (n === 'rotation') {
                            return { ...sConfig, decay: !down, velocity: [0, azimuthVelocity, 0] }
                        }
                        return sConfig
                    },
                });

                invalidate()
                return [y, x]
            },

            onWheel: ({ event }) => {
                if (!enabled) return;
                event.preventDefault();
                event.stopPropagation();
                const zoomDelta = event.deltaY * 0.001;

                setZoomState(MathUtils.clamp(zoomState * (1 + zoomDelta), zoom[0], zoom[1]))
                invalidate()


            },
            onPinch: ({ offset, event, first, last }) => {
                if (!enabled) return;
                if (first) setPinching(true);
                if (last) setPinching(false);

                event.preventDefault();
                event.stopPropagation();
                const maxOffset = zInitial / zoom[0];
                const minOffset = zInitial / zoom[1];
                if (offset[0] < minOffset) {
                    offset[0] = minOffset
                }
                if (offset[0] > maxOffset) {
                    offset[0] = maxOffset
                }
                setZoomState(MathUtils.clamp(zInitial / offset[0], zoom[0], zoom[1]))
                invalidate()
            }

        },
        { target: global ? explDomElement : undefined, eventOptions: { passive: false } }
    )


    const clock = React.useRef(new THREE.Clock()).current;
    const closeEnough = 0.01;
    const dampingFactor = [10, 15, 15];
    const rotationStateDampedRef = React.useRef(rotationStateDamped);
    const zoomStateDampedRef = React.useRef(zoomStateDamped);

    useFrame(() => {

        const delta = clock.getDelta();
        let shouldInvalidate = false;

        rotationStateDampedRef.current = rotationStateDampedRef.current.map((prevValue, index) => {
            const targetValue = rotationState[index];
            const difference = targetValue - prevValue;
            if (Math.abs(difference) >= closeEnough) {
                shouldInvalidate = true;
                const increment = difference * dampingFactor[index] * delta;
                return prevValue + increment;
            }
            return prevValue;
        });

        const zoomDifference = zoomState - zoomStateDampedRef.current;
        if (Math.abs(zoomDifference) >= closeEnough) {
            shouldInvalidate = true;
            const increment = zoomDifference * dampingFactor[2] * delta;
            zoomStateDampedRef.current = zoomStateDampedRef.current + increment;
        }

        if (shouldInvalidate) {
            setRotationStateDamped(rotationStateDampedRef.current);
            setZoomStateDamped(zoomStateDampedRef.current);
            invalidate();
        }
    });

    React.useEffect(() => {

        const zoomVector = new THREE.Vector3(0, 0, zoomStateDamped)
        zoomVector.applyQuaternion(camera.quaternion)
        const y = zoomStateDamped * Math.cos(rotationStateDamped[0] * 0.999 - Math.PI / 2)
        const z = zoomStateDamped * Math.sin(rotationStateDamped[0] * 0.999 - Math.PI / 2)
        camera.position.set(0, y, z);
        camera.lookAt(new THREE.Vector3(0, 0, 0))

    }, [zoomStateDamped, rotationStateDamped[0]])

    React.useEffect(() => {
        if (enabled) {
            explDomElement.style.touchAction = 'none';
        } else {
            explDomElement.style.touchAction = '';
        }
        const handleTouchMove = (e) => { if (enabled) e.preventDefault() }
        explDomElement.addEventListener("touchmove", handleTouchMove, { passive: false, })
        return () => {
            explDomElement.removeEventListener("touchmove", handleTouchMove)
        }
    }, [enabled, explDomElement])

    return (
        <a.group rotation-y={rotationStateDamped[1]}>
            {children}
        </a.group>
    )
}