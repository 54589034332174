import { ScreenSpace, shaderMaterial } from "@react-three/drei";
import { useThree, extend, useFrame } from "@react-three/fiber";
import { useRef } from "react";
import { Color } from "three";
import * as THREE from 'three'


const GradientMaterial = shaderMaterial(
    //Uniforms
    {
        uColor1: { value: null },
        uColor2: { value: null },
        uPolarAngle: { value: null }
    },
    //Vertex Shader
    `
    varying vec2 vUv;
  
    void main() {
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
    `,

    // Fragment Shader
    `
    uniform vec3 uColor1;
    uniform vec3 uColor2;
	uniform float uPolarAngle;
	  
	varying vec2 vUv;

	const float DITHER_INTENSITY= 0.05 ;
	const float VERTICAL_SCROLL_INTENSITY = 0.2;

	float random(highp vec2 coords) {
   	return fract(sin(dot(coords.xy , vec2(12.9898,78.233))) * 43758.5453);
	}

	void main() {
	float grad = smoothstep(0.0, 1.0, vUv.y * (1.0-VERTICAL_SCROLL_INTENSITY) - VERTICAL_SCROLL_INTENSITY  * (uPolarAngle + 1.57079));
	float noise = random(gl_FragCoord.xy / vec2(1000.0,1000.0));

	float contrast = sqrt((uColor2.x - uColor1.x) * (uColor2.x - uColor1.x) + (uColor2.y- uColor1.y)*(uColor2.y- uColor1.y) + (uColor2.z - uColor1.z)*(uColor2.z - uColor1.z))/sqrt(3.0) ;
			
	noise *= (1.1 - contrast) * DITHER_INTENSITY;

	gl_FragColor = vec4(mix(uColor2, uColor1, grad + noise), 1.0);
	}
	  	`
)

extend({ GradientMaterial })

export default function GradientBg({color="gray", contrast=0.5}) {
    const ref = useRef()
    const materialRef = useRef()
    const { camera, size, } = useThree()
    const scale = Math.tan(0.0174533 * camera.fov / 2) * camera.far * 2

    var col1HSL = RGBToHSL(HEXToRGB(color))
    col1HSL[2] = Math.max(col1HSL[2] - contrast * 40, 0)
    col1HSL[1] = Math.min(col1HSL[1] + contrast * 10, 100)
    var col1 = HSLToRGB(col1HSL)
    col1 = new Color(col1[0], col1[1], col1[2])


    var col2HSL = RGBToHSL(HEXToRGB(color))
    col2HSL[2] = Math.min(col1HSL[2] + contrast * 80, 100)
    var col2 = HSLToRGB(col2HSL)
    col2 = new Color(col2[0], col2[1], col2[2])

    var polarAngle = 0
    useFrame(() => {
            polarAngle = new THREE.Spherical().setFromVector3(camera.getWorldPosition(new THREE.Vector3())).phi
            materialRef.current.uniforms.uPolarAngle.value = -polarAngle

    })
    return <>
        <ScreenSpace depth={camera.far}>
            <mesh scale={[size.width / size.height * scale, scale, 1]}>
                <planeGeometry />
                <gradientMaterial
                    ref={materialRef}
                    uniforms-uColor1-value={col1}
                    uniforms-uColor2-value={col2}
                />
            </mesh>
        </ScreenSpace>
    </>
}

const HEXToRGB = hex =>
  hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
             ,(m, r, g, b) => '#' + r + r + g + g + b + b)
    .substring(1).match(/.{2}/g)
    .map(x => parseInt(x, 16))


    const RGBToHSL = (color) => {
        const r = color[0] / 255;
        const g = color[1] / 255;
        const b = color[2] / 255;
        const l = Math.max(r, g, b);
        const s = l - Math.min(r, g, b);
        const h = s
          ? l === r
            ? (g - b) / s
            : l === g
            ? 2 + (b - r) / s
            : 4 + (r - g) / s
          : 0;
        return [
          60 * h < 0 ? 60 * h + 360 : 60 * h,
          100 * (s ? (l <= 0.5 ? s / (2 * l - s) : s / (2 - (2 * l - s))) : 0),
          (100 * (2 * l - s)) / 2,
        ];
      };

      const HSLToRGB = (color) => {
        const h = color[0]
        const s = color[1] / 100
        const l = color[2] / 100
        const k = n => (n + h / 30) % 12;
        const a = s * Math.min(l, 1 - l);
        const f = n =>
          l - a * Math.max(-1, Math.min(k(n) - 3, Math.min(9 - k(n), 1)));
        return [f(0), f(8), f(4)];
      };
