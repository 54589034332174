


import { useState, useRef, Suspense, useCallback, useMemo } from "react"
import { Canvas } from "@react-three/fiber"
import GradientBg from "../utils/gradientbg.jsx"
import Studio from "../utils/studio.jsx"
import { AccumulativeShadows, Center, Resize, RandomizedLight, useGLTF } from "@react-three/drei"
import FixedControls from "../utils/fixedcontrols.tsx"


export default function Demo1() {

    const [color, setColor] = useState('#AABACB')
    const [contrast, setContrast] = useState(0.6)
    const [isOn, setIsOn] = useState(true)


    const handleColorChange = useCallback((e) => { setColor(e.target.value) }, []);
    const handleContrastChange = useCallback((e) => { setContrast(e.target.value) }, []);
    const handleToggleChange = useCallback((e) => { setIsOn(e.target.checked) }, []);



    const memoModel = useMemo(() => <Model isOn={isOn} />, [isOn]);

    return <>
        <div className="canvas-wrapper">

            <Canvas shadows camera={{ fov: 20, near: 0.1, far: 100 }} frameloop="demand" >
                <color attach="background" args={[color]} />

                <group visible={isOn}>
                    <GradientBg contrast={contrast} color={color} />
                </group>

                <FixedControls cursor zoomInit={4} rotationInit={[0.2, -2.5]} zoom={[1.5, 6]} speed={[2.5, 2.5]}>
                    <Suspense >
                        {memoModel}
                    </Suspense>
                </FixedControls>

                <Suspense>
                    <Studio amount={4} height={0.5} intensity={0.5} size={1} orientation={60} />
                </Suspense>



            </Canvas>

            <div className="on-off">
                <label className="switch">
                    <input type="checkbox" checked={isOn} onChange={handleToggleChange} />
                    <span className="slider round"></span>
                </label>
            </div>
        </div>

        <div className="demo-controls">
            <div className="demo-controls-item">
                Color
                <input className="color-input" type="color" value={color} onChange={handleColorChange} />
            </div>
            <div className="demo-controls-item">
                Contrast
                <input type="range" className="slider-input" min={0} max={1} step={0.01} value={contrast} onChange={handleContrastChange} />
            </div>
        </div>

    </>
}

function Model(props) {
    const { scene } = useGLTF("./models/jacquemus.glb")
    scene.traverse((obj) => (obj.isMesh && (obj.castShadow = true)));
    return <>
        <Center>
            <Resize>
                <group>
                    <primitive object={scene} />
                </group>
            </Resize>
        </Center>

        <AccumulativeShadows frames={150} toneMapped alphaTest={1.2} opacity={1} scale={3} position-y={-0.195} visible={props.isOn}>
            <RandomizedLight amount={10} radius={6} intensity={1} ambient={0.6} position={[0, 10, 0]} bias={0.001} />
        </AccumulativeShadows>
    </>
}
