
import { Environment, Lightformer } from "@react-three/drei"

export default function Studio({ size = 1, intensity = 1, height = 0.5, temperature = 0, amount = 4, debug = false, orientation = 0, preset = null }) {
    const s = size * 2 + 0.5
    const sizes = [s, s, 1]

    const int = intensity * 100 / (size * 8 + 1)
    const getIntensity = (index) => {

        return (index % 2 === 0 ? (1 + height) * int : int)
    }

    const normTemp = (temperature / 10 + 1) / 2
    const red = Math.round(255 * normTemp)
    const green = 127
    const blue = Math.round(255 * (1 - normTemp))
    const color = `rgb(${red}, ${green}, ${blue})`

    const dist = 1.5

    if (amount > 0) {
        const angle = 2 * Math.PI / amount
        const pos1 = [Math.cos(angle)]
    }
    const getPostion = (index) => {
        var position = [0, 0, 0]
        if (amount > 0) {
            const angle = -Math.PI / 1.25
            const heightBias = Math.sqrt(1 - height * height)
            if (index === 2 || index === 3) {
                position = [-Math.cos(angle * (index + 2)) * dist, -0.3, Math.sin(angle * (index + 2)) * dist]

            } else {
                if (index === 6) {
                    position = [0, -2, 0]

                }
                else {
                    position = [-Math.cos(angle * (index + 2)) * heightBias * dist, height * dist, Math.sin(angle * (index + 2)) * heightBias * dist]

                }
            }
        }
        return position
    }


    return <>
        <group rotation-y={orientation * 0.0174533} visible={debug}>
            <Lightformer form="rect" intensity={int / 6} rotation-y={Math.PI / 2} position={getPostion(1)} scale={sizes} target={[0, 0, 0]} visible={amount > 0} color={color} />
            <Lightformer form="rect" intensity={int / 6} rotation-y={Math.PI / 2} position={getPostion(2)} scale={sizes} target={[0, 0, 0]} visible={amount > 1} color={color} />
            <Lightformer form="rect" intensity={int / 6} rotation-y={Math.PI / 2} position={getPostion(3)} scale={sizes} target={[0, 0, 0]} visible={amount > 2} color={color} />
            <Lightformer form="rect" intensity={int / 6} rotation-y={Math.PI / 2} position={getPostion(4)} scale={sizes} target={[0, 0, 0]} visible={amount > 3} color={color} />
            <Lightformer form="rect" intensity={int / 6} rotation-y={Math.PI / 2} position={getPostion(5)} scale={sizes} target={[0, 0, 0]} visible={amount > 4} color={color} />
            <Lightformer form="rect" intensity={int / 6} rotation-y={Math.PI / 2} position={getPostion(6)} scale={sizes} target={[0, 0, 0]} visible={amount > 5} color={color} />

        </group>

        <Environment preset={preset}>
            {/* <color attach="background" args={["black"]} /> */}

            <group rotation-y={orientation * 0.0174533}>
                <Lightformer form="rect" intensity={getIntensity(1)} rotation-y={Math.PI / 2} position={getPostion(1)} scale={sizes} target={[0, 0, 0]} visible={amount > 0} color={color} />
                <Lightformer form="rect" intensity={getIntensity(2)} rotation-y={Math.PI / 2} position={getPostion(2)} scale={sizes} target={[0, 0, 0]} visible={amount > 1} color={color} />
                <Lightformer form="rect" intensity={getIntensity(3)} rotation-y={Math.PI / 2} position={getPostion(3)} scale={sizes} target={[0, 0, 0]} visible={amount > 2} color={color} />
                <Lightformer form="rect" intensity={getIntensity(4)} rotation-y={Math.PI / 2} position={getPostion(4)} scale={sizes} target={[0, 0, 0]} visible={amount > 3} color={color} />
                <Lightformer form="rect" intensity={getIntensity(5)} rotation-y={Math.PI / 2} position={getPostion(5)} scale={sizes} target={[0, 0, 0]} visible={amount > 4} color={color} />
                <Lightformer form="rect" intensity={getIntensity(6) *0.2} rotation-y={Math.PI / 2} position={getPostion(6)} scale={sizes} target={[0, 0, 0]} visible={amount > 5} color={color} />
            </group>
        </Environment>


    </>

}