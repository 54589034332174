import { Canvas } from "@react-three/fiber";
import { Suspense, useState, useMemo, useRef, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { useGLTF, Center, Resize, AccumulativeShadows, RandomizedLight, Html, useProgress } from "@react-three/drei";
import GradientBg from "./utils/gradientbg";
import FixedControls from "./utils/fixedcontrols";
import Studio from "./utils/studio.jsx"
import PostProcessing from "./utils/postprocessing.jsx"
import Fade from "./utils/fade";




export default function Models() {
    const location = useLocation()
    const match = location.pathname.match(/\d+$/)
    const modelNumber = match ? parseInt(match[0]) : -1;

    return <>
        {modelNumber === -1 && <Grid />}
        {(modelNumber > 0 && modelNumber <= 16) && <ModelPage modelNumber={modelNumber} />}
    </>
}

function Grid() {
    const items = Array.from({ length: 16 }, (_, i) => i + 1);

    return (
        <>
            <div className="grid-parent">
                <Fade delay={0.3}>

                    <div className="grid-container">
                        {items.map((item) => (
                            <Link key={item} to={`/3Dmodels-${item}`}>
                                <div className="grid-item"
                                    style={{
                                        backgroundImage: `url(../thumbnails/${item}.png)`,
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center'
                                    }}>
                                </div>
                            </Link>
                        ))}
                    </div>

                </Fade>
            </div>
        </>
    );
}

function Loader() {
    const { progress } = useProgress()
    return <>
        <Html center>
            <div className="loader">
                <div className="progress" style={{ width: `${Math.min(progress * 3, 100)}%` }} />
            </div>
        </Html>
    </>
}


function ModelPage(props) {
    const [aspectRatio, setAspectRatio] = useState(null);
    const divRef = useRef(null);

    useEffect(() => {
        if (divRef.current) {
            const calculateAspectRatio = () => {
                const width = divRef.current.offsetWidth;
                const height = divRef.current.offsetHeight;
                setAspectRatio(width / height);
            };

            calculateAspectRatio();

            const ro = new ResizeObserver(calculateAspectRatio);
            ro.observe(divRef.current);

            return () => ro.disconnect();
        }
    }, []);


    const memoModel = useMemo(() => <Model number={props.modelNumber} />)
    const memoScene = useMemo(() => <Scene number={props.modelNumber} />)
    const previous = `/3Dmodels-${Math.max(props.modelNumber - 1, 1)}`
    const next = `/3Dmodels-${Math.min(props.modelNumber + 1, 16)}`
    const memoPostProcessing = useMemo(() => <PostProcessing focusPlane={0.0003} bloomIntensity={0} offset={0.25} />, []);


    const rotations = {
        1: [0, -0.5],
        2: [0, -0.5],
        3: [0, 1.02],
        4: [0, 0.5],
        5: [0, 1],
        6: [0.02, 0.95],
        7: [0, 0],
        8: [0, 0],
        9: [0, 1],
        10: [0, -0.5],
        11: [0, 0.5],
        12: [0, 1],
        13: [0, -1],
        14: [0, 0],
        15: [0, -1],
        16: [0, -1]
    }

    return <>
        <div className="section demo">
            <Link to="/3Dmodels" className="close-button" />
            <div className="canvas-wrapper" ref={divRef}>
                <Canvas shadows dpr={[1, 2]} camera={{ fov: 20 }} frameloop="demand">
                    <Suspense>
                        {memoScene}
                    </Suspense>
                    <Suspense>{memoPostProcessing}</Suspense>

                    <FixedControls cursor key={props.modelNumber} rotationInit={[rotations[props.modelNumber][0] * Math.PI, rotations[props.modelNumber][1] * Math.PI]} zoomInit={3.5 / Math.min(1, aspectRatio)} zoom={[1, 6]} speed={[2.5, 2.5]}>
                        <Suspense fallback={<Loader />}>
                            {memoModel}
                        </Suspense>
                        {/* <Loader /> */}
                    </FixedControls>
                </Canvas>

            </div>
            <div className="demo-footer">
                <div className="demo-nav">
                    <Link className={`${props.modelNumber === 1 ? 'arrow-disabled' : ''} arrow arrow-left`} to={previous} />
                    <Link className={`${props.modelNumber === 16 ? 'arrow-disabled' : ''} arrow arrow-right`} to={next} />
                </div>
                <div className="counter">
                    {props.modelNumber}/16
                </div>
            </div>

        </div>
    </>
}

function Model(props) {
    const paths = {
        1: "../models/mule/item.gltf",
        2: "../models/sneaker/item.gltf",
        3: "../models/mask/item.gltf",
        4: "../models/balmainboot.glb",
        5: "../models/heart/item.gltf",
        6: "../models/cesar.glb",
        7: "../models/liner/item.gltf",
        8: "../models/chloebeige.glb",
        9: "../models/flacon.glb",
        10: "../models/bois/item.gltf",
        11: "../models/nb.glb",
        12: "../models/earring.glb",
        13: "../models/bombe/item.gltf",
        14: "../models/moon/item.gltf",
        15: "../models/rouge/item.gltf",
        16: "../models/dagger.glb",
    }
    const move = {
        1: [0, -0.01, 0, 0, 1, 0, 0],
        2: [0, 0, 0, 0, 1, 0, 0],
        3: [0, 0, 0, 0, 1, 0.06, -0.05],
        5: [0.7, -0.03, 0, 0, 2.4, 0, -0.07],
        6: [-0.1, 0, 0, 0, 1, 0, 0],
        12: [0, 0, 0, 0, 0.9, 0, -0.15],
        13: [0, 0, 0, 0.011, 1, 0, 0],
        16: [0, 0, 0, 0, 1.2, -0.1, 0],
    }

    const { scene } = useGLTF(paths[props.number])

    useEffect(() => {
        return () => {
            scene.traverse((object) => {
                if (!object.isMesh) return
                if (object.geometry) {
                    object.geometry.dispose()
                }
                if (object.material) {
                    if (object.material.length) {
                        for (let i = 0; i < object.material.length; ++i) {
                            object.material[i].dispose()
                        }
                    }
                    else {
                        object.material.dispose()
                    }
                }
            })
        }
    }, [props.number, scene])

    scene.traverse((obj) => (obj.isMesh && (obj.castShadow = true)))

    const [height, setHeight] = useState(0.5)
    const vertical = props.number === 14
    let shadow = -height / 2
    if (move[props.number]) {
        shadow = vertical ? 0 : move[props.number][0] - height * move[props.number][4] / 2 + move[props.number][6]
    }
    return <>
        <Center key={props.number} onCentered={({ height, container }) => { 
            
            setHeight(height)
            if (move[props.number]) {
                container.scale.setScalar(move[props.number][4])
                container.position.setY(move[props.number][0])
                container.position.setZ(move[props.number][5])
                container.rotation.set(move[props.number][1], move[props.number][2], move[props.number][3])
            }

        }}>
            <Resize>
                <primitive object={scene} />
            </Resize>
        </Center>
        <AccumulativeShadows frames={150} toneMapped alphaTest={1.1} opacity={1} color="#96A5B9" scale={2} position-y={shadow} position-z={vertical ? 0.04 : 0} rotation={vertical ? [-Math.PI / 2, 0, 0] : [0, 0, 0]} >
            <RandomizedLight amount={10} radius={2} intensity={1} ambient={0.7} position={[0, 8, 0]} bias={0.001} />
        </AccumulativeShadows>
    </>
}

function Scene(props) {
    const lights = {
        1: [4, 1, 180, 1],
        2: [5, 0.8, 180, 0.5],
        3: [2, 1.2, 80, 1.5],
        4: [5, 1, 140],
        5: [5, 1, 170, 0.9],
        6: [5, 1, 180, 0.5],
        7: [3, 1.2, 180, 1],
        8: [5, 0.7, 180, 1],
        9: [3, 1.2, 180, 1],
        10: [5, 1, 180, 0.7],
        11: [4, 0.9, -50, 0.8],
        12: [6, 1, 180, 1],
        13: [5, 1, 180, 0.7],
        14: [5, 1.5, 180, 0.1],
        15: [4, 0.7, 180, 1.2],
        16: [5, 0.8, 120, 1.2],
    }
    return <>
        <GradientBg contrast={0.5} color={"#C0D0E0"} />
        <Studio amount={lights[props.number][0] ?? 4} height={0.7} intensity={lights[props.number][1] ?? 1} orientation={lights[props.number][2] ?? 180} size={lights[props.number][3] ?? 0.8} />
    </>
}