import './style.css'
import Fade from "./utils/fade.jsx";


export default function Contact() {
    return <>
        <div className='spacer' />
        <div className='section'>
            <div className='contact-text'>
                <Fade>
                    <p> For inquiries and availabilities: <a href='mailto:victormustin@gmail.com'>victormustin<span className='at'>@</span>gmail.com</a> </p>
                </Fade>
                <Fade delay={0.05}>
                    <p>twitter: <a href="https://twitter.com/victormustin">@victormustin</a></p>
                </Fade> 
                <Fade delay={0.1}>
                    <p>ig: <a href="https://www.instagram.com/mustinvictor/">@mustinvictor</a></p>
                </Fade>
          
            </div>

        </div>
    </>
}
