import { ReactComponent as SvgLogo } from './assets/logo.svg'
import Contact from './contact.jsx'
import Fade from './utils/fade.jsx'
import { Link } from 'react-router-dom'

export default function Home() {
  return (
    <>
      <div className='section about'>
        <Fade>
          <div className='img-about'>
            <SvgLogo className='svg-logo' />
          </div>
        </Fade>
        <div className='text-about'>
          <Fade>
            <h1>
              Hi, I'm Victor Mustin, a software developer with a strong focus on
              product development.
            </h1>
            <h1>For inquiries and availability : victormustin@gmail.com </h1>
          </Fade>
        </div>
      </div>
      <div className='section'>
        <div className='exp-wrapper'>
          <Fade delay={0.5}>
            <div className='exp-title'>Co-founder</div>
            <div className='exp-subtitle'>NEVERSCENE — 2024 - current</div>
            <div className='separator' />
          </Fade>
          <Fade delay={0.5}>
            <ul className='smaller'>
              <li>
                <p>
                  Nevercsene is an AI app that helps interior designers find
                  quality inspiration and share new content thanks to AI image
                  generation. It studies your moodboard and references to be +++
                  accurate and useful.
                </p>
              </li>
            </ul>

            <div className='a-wrapper'>
              <a
                className='exp-link'
                href='https://www.neverscene.ai/'
                target='_blank'
                rel='noopener noreferrer'
              >
                Got to Website
              </a>
            </div>
          </Fade>
        </div>
        <div className='exp-wrapper'>
          <Fade delay={0.6}>
            <div className='exp-title'>Fullstack Creative Developer</div>
            <div className='exp-subtitle'>FREELANCE — 2023 - current</div>
            <div className='separator' />
          </Fade>
          <Fade delay={0.6}>
            <ul className='smaller'>
              <li>
                <p>
                  Web Stack : React, Next.js, Postgres, Mysql, AWS, three.js,
                  tailwind
                </p>
              </li>

              <li>
                <p>
                  3D Stack : Blender, Zbrush, CLO3D, Substance Suite, Marmoset
                </p>
              </li>
            </ul>
          </Fade>
        </div>
        <div className='exp-wrapper'>
          <Fade delay={0.7}>
            <div className='exp-title'>3D human interface design</div>
            <div className='exp-subtitle'>VICEVERSA — 2022</div>
            <div className='separator' />
          </Fade>
          <Fade delay={0.7}>
            <ul className='smaller'>
              <li>
                <p>
                  Viceversa is a 3D technology suite tailored for the fashion
                  and luxury industries that enhances e-commerce websites with
                  immersive and interactive 3D experiences.
                </p>
              </li>
              <li>
                <p>R&D position working on :</p>
                <ul className='no-bullet'>
                  <li>
                    <p>
                      1 — Ensuring a seamless and captivating viewing experience
                      with careful consideration on how the object moves on user
                      touch, the way lighting, shadows and reflections behave,
                      how they are rendered on-screen and more.
                    </p>

                    {/* </li>
                                <li> */}
                    <p>
                      2 — Developing user-friendly tools that enable clients to
                      edit their 3D items and virtual scenes. These tools are
                      designed for seamless use by anyone on the client's team,
                      regardless of their 3D experience, while upholding the
                      high visual standards expected in luxury fashion.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <p>
                  Clients include : LVMH, Kering, Givenchy, Montblanc, Sephora,
                  Le Gramme, Salomon
                </p>
              </li>
            </ul>
          </Fade>
          <Fade delay={0.8}>
            <div className='a-wrapper'>
              <Link className='exp-link' to='/demos-1'>
                View Demos
              </Link>
            </div>
          </Fade>
        </div>
        <div className='exp-wrapper'>
          <Fade delay={0.9}>
            <div className='exp-title'>
              3D studio lead (photogrammetry & 3D modeling)
            </div>
            <div className='exp-subtitle'>VICEVERSA — 2021-2022</div>
            <div className='separator' />
          </Fade>
          <Fade delay={1}>
            <ul className='smaller'>
              <li>
                <p>
                  Research & development, implementation, teaching and
                  documentation of a complete AAA pipeline for creating 3D twins
                  for luxury fashion.
                </p>
                <p>
                  This pipeline spanned across photogrammetry scanning, data
                  processing, high-precision sculpting and modeling, texturing,
                  optimisation for real-time rendering, animation and more.
                </p>
                <p>
                  Working with couture and luxury clients came with very high
                  visual standards, all the while the models needed to render
                  smoothly in real-time for mobile and desktop users.
                </p>
              </li>
              <li>
                <p>Led a team of 6 3D artists + 4 outsourcing 3D studios.</p>
              </li>
              <li>
                <p>
                  Clients include : LVMH, Chanel, Alaïa, Chloé, Nodaleto,
                  Jacquemus, Wethenew, Pamela Love.
                </p>
              </li>
            </ul>
          </Fade>
          <Fade delay={1.1}>
            <div className='a-wrapper'>
              <Link className='exp-link' to='/3Dmodels'>
                View 3D models
              </Link>
            </div>

            <div className='a-wrapper'>
              <a
                className='exp-link'
                href='https://www.lemonde.fr/m-styles/article/2022/02/19/la-mode-se-pique-de-clonage-virtuel_6114374_4497319.html'
                target='_blank'
                rel='noopener noreferrer'
              >
                Read press from Le Monde (FR)
              </a>
            </div>
          </Fade>
        </div>
        <div className='exp-wrapper'>
          <Fade delay={1.2}>
            <div className='exp-title'>Augmented reality designer</div>
            <div className='exp-subtitle'>BARNEYSTUDIOS — 2020-2021</div>
            <div className='separator' />
          </Fade>
          <Fade delay={1.3}>
            <ul className='smaller'>
              <li>
                <p>
                  Design and development of AR experiences, AR filters, virtual
                  try-on’s.
                </p>
              </li>
              <li>
                <p>
                  Clients include : Netflix, Adidas, Westfiled, Chloé, Givenchy,
                  Zadig&Voltaire, Louboutin, Nodaleto, Paris Olympic Games.
                </p>
              </li>
            </ul>
          </Fade>
        </div>
        <div className='exp-wrapper'>
          <Fade delay={1.4}>
            <div className='exp-title'>Augmented reality designer</div>
            <div className='exp-subtitle'>FREELANCE — 2019-2021</div>
            <div className='separator' />
          </Fade>
          <Fade delay={1.5}>
            <ul className='smaller'>
              <li>
                <p>
                  Design and development of AR experiences, AR filters, virtual
                  try-on’s.
                </p>
              </li>
              <li>
                <p>
                  Clients include : A24, Paramount+, Prime Video, Blackpink,
                  H&M, Evian, YSL, Calvin Klein.
                </p>
              </li>
            </ul>
          </Fade>
        </div>
        <div className='footer' />
        {/* <Contact/> */}
      </div>
    </>
  )
}
