import { useState, useRef, Suspense, useCallback, useMemo } from "react"
import { Canvas, useLoader } from "@react-three/fiber"
import GradientBg from "../utils/gradientbg.jsx"
import { Center, OrbitControls, Resize, useGLTF, Environment, MeshRefractionMaterial, CubeCamera } from "@react-three/drei"
import { RGBELoader } from 'three-stdlib'
import PostProcessing from "../utils/postprocessing.jsx"
import FixedControls from "../utils/fixedcontrols.tsx"


export default function Demo2() {
    const [isOn, setIsOn] = useState(true)
    const handleToggleChange = useCallback((e) => { setIsOn(e.target.checked); }, []);
    const memoScene = useMemo(() => <Scene />, []);
    const memoModel = useMemo(() => <Model isOn={isOn} />, [isOn]);
    const memoEffects = useMemo(() => <PostProcessing />, []);

    return <>
        <div className="canvas-wrapper">
            <Canvas camera={{ fov: 20, near: 0.01, far: 50 }} frameloop="demand" flat={!isOn} className="demo-canvas" >
                <FixedControls cursor zoomInit={0.22} rotationInit={[0, 3.5]} zoom={[0.1, 2]} speed={[2.5, 2.5]}>
                    <Suspense>{memoModel}</Suspense>
                </FixedControls>
                <Suspense>{memoScene}</Suspense>
                {isOn && <Suspense>{memoEffects}</Suspense>}
            </Canvas>
            <div className="on-off">
                <label className="switch">
                    <input type="checkbox" checked={isOn} onChange={handleToggleChange} />
                    <span className="slider round"></span>
                </label>
            </div>

        </div>

    </>
}

function Scene() {
    return <>
        <GradientBg contrast={0.4} color={"#CAC9C7"} />
        <Environment files="./hdri/custom9.hdr" />
    </>
}

function Model(props) {
    const texture = useMemo(() => { return useLoader(RGBELoader, "./hdri/diamond.hdr"); }, []);

    const { nodes, materials } = useMemo(() => { return useGLTF("./models/viltier.glb"); }, []);

    return <>

        <group position={[0.0135, 0.465, -0.002]}>
            <Center>
                <Resize>

                    <group {...props} dispose={null}>
                        <group position={[-0.33, 1.86, 0.11]} scale={[1.1, 1.1, 1]}>
                            <CubeCamera resolution={256} frames={1} envMap={texture} >
                                {(texture) => (
                                    <mesh geometry={nodes.Diamonds.geometry} >
                                        <MeshRefractionMaterial envMap={texture} toneMapped={false} ior={3} aberrationStrength={0.002} bounces={props.isOn ? 3 : 1} />
                                    </mesh>
                                )}
                            </CubeCamera>
                        </group>
                        <group position={[0.33, 1.86, 0.11]} scale={[1.1, 1.1, 1]}>
                            <CubeCamera resolution={256} frames={1} envMap={texture} >
                                {(texture) => (
                                    <mesh geometry={nodes.Diamonds001.geometry} >
                                        <MeshRefractionMaterial envMap={texture} toneMapped={false} ior={3} aberrationStrength={0.002} bounces={props.isOn ? 3 : 1} />

                                    </mesh>
                                )}
                            </CubeCamera>
                        </group>
                        <mesh castShadow geometry={nodes.Necklace.geometry} material={materials.Necklace} position={[0.31, 14.22, 0]} />
                    </group>
                </Resize>
            </Center>

        </group>
    </>
}

