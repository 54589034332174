import { useState, useRef, Suspense, useCallback, useMemo } from "react"
import { Canvas } from "@react-three/fiber"
import GradientBg from "../utils/gradientbg.jsx"
import Studio from "../utils/studio.jsx"
import { AccumulativeShadows, Center, OrbitControls, Resize, RandomizedLight, useGLTF, View } from "@react-three/drei"
import FixedControls from "../utils/fixedcontrols.tsx"


export default function Demo3() {
    const [amount, setAmount] = useState(5)
    const [height, setHeight] = useState(0.7)
    const [intensity, setIntensity] = useState(0.93)
    const [size, setSize] = useState(0.78)
    const [temperature, setTemperature] = useState(0)
    const [orientation, setOrientation] = useState(-180)

    const handleAmountChange = useCallback((e) => { setAmount(parseFloat(e.target.value)); }, []);
    const handleHeightChange = useCallback((e) => { setHeight(parseFloat(e.target.value)); }, []);
    const handleIntensityChange = useCallback((e) => { setIntensity(parseFloat(e.target.value)); }, []);
    const handleSizeChange = useCallback((e) => { setSize(parseFloat(e.target.value)); }, []);
    const handleTemperatureChange = useCallback((e) => { setTemperature(parseFloat(e.target.value)); }, []);
    const handleOrientationChange = useCallback((e) => { setOrientation(parseFloat(e.target.value)); }, []);


    const memoModel = useMemo(() => <Model />, []);

    return <>

        <Canvas shadows camera={{ fov: 20, near: 0.1, far: 100 }} frameloop="demand" >
            <GradientBg contrast={0.4} color={"#CAC9C7"} />
            <FixedControls cursor zoomInit={3.5} rotationInit={[0, 0]} zoom={[1.5, 6]} speed={[2.5, 2.5]}>
                <Suspense >{memoModel}</Suspense>
            </FixedControls>

            <Suspense>
                <Studio amount={amount} height={height} intensity={intensity} size={size} temperature={temperature} orientation={orientation} />
            </Suspense>
    
        </Canvas>

 
        <div className="demo-controls">
            <div className="demo-controls-item">
                {amount} {amount === 1 ? "Light" : "Lights"} <input type="range" className="slider-input" min={0} max={6} step={1} value={amount} onChange={handleAmountChange} />
            </div>
            <div className="demo-controls-item">
                Intensity<input type="range" className="slider-input light" min={0} max={1.2} step={0.01} value={intensity} onChange={handleIntensityChange} />
            </div>
            <div className="demo-controls-item">
                Softbox size<input type="range" className="slider-input" min={0} max={1} step={0.01} value={size} onChange={handleSizeChange} />
            </div>
            <div className="demo-controls-item">
                Orientation<input type="range" className="slider-input" min={-180} max={180} step={1} value={orientation} onChange={handleOrientationChange} />
            </div>
            <div className="demo-controls-item">
                Top light<input type="range" className="slider-input" min={0} max={0.8} step={0.01} value={height} onChange={handleHeightChange} />
            </div>
            {/* <div className="demo-controls-item">
                Temperature<input type="range" className="slider-input temperature" min={-1} max={1} step={0.01} value={temperature} onChange={handleTemperatureChange} />
            </div> */}
        </div>
    </>
}

function Model() {
    const { scene } = useGLTF("./models/chloe.glb")
    scene.traverse((obj) => (obj.isMesh && (obj.castShadow = true)));
    return <>
        <Center>
            <Resize>
                <group rotation-z={-0.15} rotation-y={0.08}>
                    <primitive object={scene} />
                </group>
            </Resize>
        </Center>
        <AccumulativeShadows frames={150} toneMapped alphaTest={1.2} opacity={1} scale={3} position-y={-0.5} color="#C1B29E">
            <RandomizedLight amount={10} radius={6} intensity={1} ambient={0.6} position={[0, 10, 0]} bias={0.001} />
        </AccumulativeShadows>
    </>
}