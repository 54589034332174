import { useState, Suspense, useCallback, useMemo } from 'react'
import { Canvas } from '@react-three/fiber'
import {
  Center,
  Resize,
  useGLTF,
  Environment,
  OrbitControls,
  CameraControls,
} from '@react-three/drei'

import PostProcessing from '../utils/postprocessing.jsx'
import PresentationControls from '../utils/presentationcontrols.tsx'

export default function Demo6() {
  const [isOn, setIsOn] = useState(true)
  const handleToggleChange = useCallback((e) => {
    setIsOn(e.target.checked)
  }, [])
  const memoScene = useMemo(() => <Scene />, [])

  return (
    <>
      <div className='canvas-wrapper'>
        <Canvas
          dpr={[1, 2]}
          camera={{ fov: 20, near: 0.01, position: [0, 0, 3.5] }}
          frameloop='demand'
        >
          <Scene isOn={isOn} />
        </Canvas>
        <div className='on-off'>
          <label className='switch'>
            <input
              type='checkbox'
              checked={isOn}
              onChange={handleToggleChange}
            />
            <span className='slider round'></span>
          </label>
        </div>
      </div>
    </>
  )
}

function Scene(props) {
  const memoModel = useMemo(() => <Model isOn={props.isOn} />, [props.isOn])

  return (
    <>
      <color attach='background' args={['#EFEAE4']} />
      <Suspense>
        <group position={[0, -0.04, -0.05]}>{memoModel}</group>
      </Suspense>
      <Environment files='./hdri/custom9.hdr' />
    </>
  )
}

const Model = (props) => {
  const { scene } = useMemo(() => {
    return useGLTF('./models/dagger.glb')
  }, [])
  scene.traverse((obj) => obj.isMesh && (obj.castShadow = true))
  return (
    <>
      <PostProcessing offset={0.1} minDist={1} focusPlane={0.001} />
      {!props.isOn && (
        <OrbitControls
          zoomSpeed={0.4}
          maxDistance={8}
          minDistance={0.5}
          enablePan={false}
        />
      )}
      {!props.isOn && (
        <Center>
          <Resize>
            <group>
              <primitive object={scene} includeConvexHull />
            </group>
          </Resize>
        </Center>
      )}
      <ObjectControls />
    </>
  )
}

const ObjectControls = () => {
  return <CameraControls />
}
