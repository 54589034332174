import * as React from 'react'
import { Mesh, Shape, ExtrudeGeometry } from 'three'
import { NamedArrayTuple } from '@react-three/drei/helpers/ts-utils'
import { mergeVertices } from 'three-stdlib'

import {
  BufferAttribute,
  BufferGeometry,
  Vector3,
} from 'three'

const eps = 0.00001

function createShape(width: number, height: number, radius0: number) {
  const shape = new Shape()
  const radius = radius0 - eps
  shape.absarc(eps, eps, eps + radius, -Math.PI / 2, -Math.PI, true)
  shape.absarc(eps, height - 2 * radius, eps + radius, Math.PI, Math.PI / 2, true)
  shape.absarc(width - 2 * radius, height - 2 * radius, eps + radius, Math.PI / 2, 0, true)
  shape.absarc(width - 2 * radius , eps, eps + radius, 0, -Math.PI / 2, true)
  return shape
}

type Props = {
  args?: NamedArrayTuple<(width?: number, height?: number, depth?: number) => void>
  radius?: number
  roundness?: number
  smoothness?: number
  steps?: number
  creaseAngle?: number
} & Omit<JSX.IntrinsicElements['mesh'], 'args'>

export const RoundedBox = React.forwardRef<Mesh, Props>(function RoundedBox(
  {
    args: [width = 1, height = 1, depth = 1] = [],
    radius = 0.05,
    roundness = 0,
    steps = 1,
    smoothness = 4,
    creaseAngle = 0.4,
    children,
    ...rest
  },
  ref
) {
  const shape = React.useMemo(() => createShape(width, height, roundness), [width, height, roundness])
  const params = React.useMemo(
    () => ({
      depth: depth - radius * 2,
      bevelEnabled: true,
      bevelSegments: smoothness * 2,
      steps,
      bevelSize: radius  - eps,
      bevelThickness: radius ,
      curveSegments: 8,
    }),
    [depth, radius, roundness, smoothness]
  )
  const geomRef = React.useRef<ExtrudeGeometry>()

  React.useLayoutEffect(() => {
    if (geomRef.current) {
      geomRef.current.center()
      mergeVertices(geomRef.current, 1)
      toCreasedNormals(geomRef.current, 2)
    }
  }, [shape, params])

  return (
    <mesh ref={ref} {...rest}>
      <extrudeGeometry ref={geomRef} args={[shape, params]} />
      {children}
    </mesh>
  )
})

function toCreasedNormals(geometry: BufferGeometry, creaseAngle = Math.PI / 3 /* 60 degrees */): BufferGeometry {
  const creaseDot = Math.cos(creaseAngle)
  const hashMultiplier = (1 + 1e-10) * 1e5

  // reusable vertors
  const verts = [new Vector3(), new Vector3(), new Vector3()]
  const tempVec1 = new Vector3()
  const tempVec2 = new Vector3()
  const tempNorm = new Vector3()
  const tempNorm2 = new Vector3()

  // hashes a vector
  function hashVertex(v: Vector3): string {
    const x = ~~(v.x * hashMultiplier)
    const y = ~~(v.y * hashMultiplier)
    const z = ~~(v.z * hashMultiplier)
    return `${x},${y},${z}`
  }

  const resultGeometry = geometry.toNonIndexed()
  const posAttr = resultGeometry.attributes.position
  const vertexMap: { [key: string]: Vector3[] } = {}

  // find all the normals shared by commonly located vertices
  for (let i = 0, l = posAttr.count / 3; i < l; i++) {
    const i3 = 3 * i
    const a = verts[0].fromBufferAttribute(posAttr, i3 + 0)
    const b = verts[1].fromBufferAttribute(posAttr, i3 + 1)
    const c = verts[2].fromBufferAttribute(posAttr, i3 + 2)

    tempVec1.subVectors(c, b)
    tempVec2.subVectors(a, b)

    // add the normal to the map for all vertices
    const normal = new Vector3().crossVectors(tempVec1, tempVec2).normalize()
    for (let n = 0; n < 3; n++) {
      const vert = verts[n]
      const hash = hashVertex(vert)
      if (!(hash in vertexMap)) {
        vertexMap[hash] = []
      }

      vertexMap[hash].push(normal)
    }
  }

  // average normals from all vertices that share a common location if they are within the
  // provided crease threshold
  const normalArray = new Float32Array(posAttr.count * 3)
  const normAttr = new BufferAttribute(normalArray, 3, false)
  for (let i = 0, l = posAttr.count / 3; i < l; i++) {
    // get the face normal for this vertex
    const i3 = 3 * i
    const a = verts[0].fromBufferAttribute(posAttr, i3 + 0)
    const b = verts[1].fromBufferAttribute(posAttr, i3 + 1)
    const c = verts[2].fromBufferAttribute(posAttr, i3 + 2)

    tempVec1.subVectors(c, b)
    tempVec2.subVectors(a, b)

    tempNorm.crossVectors(tempVec1, tempVec2).normalize()

    // average all normals that meet the threshold and set the normal value
    for (let n = 0; n < 3; n++) {
      const vert = verts[n]
      const hash = hashVertex(vert)
      const otherNormals = vertexMap[hash]
      tempNorm2.set(0, 0, 0)

      for (let k = 0, lk = otherNormals.length; k < lk; k++) {
        const otherNorm = otherNormals[k]
        if (tempNorm.dot(otherNorm) > creaseDot) {
          tempNorm2.add(otherNorm)
        }
      }

      tempNorm2.normalize()
      normAttr.setXYZ(i3 + n, tempNorm2.x, tempNorm2.y, tempNorm2.z)
    }
  }

  resultGeometry.setAttribute('normal', normAttr)
  return resultGeometry
}
